import React from 'react';




const AdminPage = () => {
    
    return (<div>Cool</div>
        
    )
}
export default AdminPage