import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import WelcomeModal from "./WelcomeModal";
import terms from "../../credentials.json";
import {
  selectFirstLogin,
  firstTimeAction,
  selectActiveUser,
  updateCrediential,
  // logout,
} from "../accounts/accountSlice";
import CrednetialsSelector from "../crednetialsSelector";

const HomePage = ({ logout }) => {
  const termList = terms.credentials;
  const [isBtnDisabled, setIsDisabled] = useState(true);
  const [selectedCredential, setCredential] = useState(termList[0]);
  const handelSelect = (e) => {
    setCredential(termList[e]);
    setIsDisabled(false)
    console.log(`New cred value ${JSON.stringify(termList[e])}`);
    console.log(`cred index ${e}`);
    // setIsDisabled(false);
  };
  const activeUser = useSelector(selectActiveUser);
  useEffect(() => {
    function getCredential() {
      setCredential(activeUser.credentials === undefined
        ? ""
        : activeUser.credentials.map((c) => {
          return c.credentialName
        }
        ))
    };
    getCredential()
  }, [activeUser.credentials]

  )

  const showWelcomModal = useSelector(selectFirstLogin);
  const dispatch = useDispatch();
  // const inputRef = useRef(null);
  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">Profile</h3>
      <div className="row mb-3">
        <div className="col-lg-4">
          <div className="card  mb-3">
            <div className="card-body shadow">
              <span>
                <img
                  alt=""
                  className=" rounded-circle mb-3 mt-4"
                  src={activeUser.photoURL}
                  width="75"
                  height="75"
                />{" "}
                <strong className=" mb-3">{activeUser.displayName}</strong>
              </span>
              {console.log(`Array.isArray(activeUser?.credentials) ${(activeUser?.credentials?.some(v => !!v?.credentialName))}`)}
              <div className=" mb-3">

                {`Credentials:  ${activeUser?.credentials?.some(v => !!v?.credentialName) ? activeUser?.credentials[0]?.credentialName
                  : "None Selected"
                  } `}</div>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={logout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row mb-3 d-none"></div>
          <div className="row">
            <div className="col">
              <div className="card shadow mb-3">
                <div className="card-header py-1">
                  <p className="text-primary m-0 font-weight-bold">
                    User Settings
                  </p>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="username">
                            <strong>Update Credential</strong>
                          </label>
                          {<CrednetialsSelector
                            termList={termList}
                            handelSelect={handelSelect}
                            startingSelection={selectedCredential} >
                          </CrednetialsSelector>}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        disabled={isBtnDisabled}
                        onClick={() => {
                          dispatch(
                            updateCrediential(
                              selectedCredential,
                              activeUser.practitionerId
                            )
                          );
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WelcomeModal
        show={showWelcomModal}
        onHide={() => {
          dispatch(firstTimeAction(false));
        }}
      ></WelcomeModal>
    </div>
  );
};
export default HomePage;
