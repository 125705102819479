import {toast} from "react-toastify"


// // /**
// //  * Show error messages on page.
// //  * @param messages
// //  */
export function showErrors(...messages ) {

    messages.forEach(x => {
        if (!Array.isArray(x)) {
            toast.error(x);
        }
        else {
            (x).forEach((y) => toast.error(y));
        }
    });
}

// // /**
// //  * Show information message on page.
// //  * @param message
// //  */
export function showInfo(message) {
    toast.info(message);
}

/**
 * Is server prerendering by NodeJs.
 * There can't be any DOM elements such as: window, document, etc.
 */
export function isNode() {
    return typeof process === 'object' && process.versions && !!process.versions.node;
}
// // /**
// //  * Get NodeJs process.
// //  * */
export function getNodeProcess() {
    if (isNode()) {
        return process;
    }
    return null;
}