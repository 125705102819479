import React from "react";
import styles from "./UnauthLayout.Module.scss";
import { ToastContainer } from "react-toastify";

const UnauthLayout = ({ children }) => {
  return (
    <div id="unauthLayout" className={styles.unauthLayout}>
      {/* <h1 className="container container-content">Welcome to FHIR Scan</h1> */}
      <div>{children}</div>
      <ToastContainer/>
    </div>
  );
};
export default UnauthLayout;
