import styles from "./AuthLayout.Module.scss";
import bannerImage from "../../src/assets/FHIRScanLogo1.svg";
import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";
import {
  // logout,
  selectActiveUser,
  // selectIsAuthenticated,
} from "../features/accounts/accountSlice";
import "firebase/auth";
import ConditionalComponent from "../ConditionalComponent";
import { ToastContainer } from "react-toastify";
import Navbar from "react-bootstrap/Navbar";

import Nav from "react-bootstrap/Nav";

function AuthLayout({ logout, children }) {
  const patient = useSelector((state) => state.patient.activePatient);
  //  const activeUser = firebase.auth().currentUser;
  const activeUser = useSelector(selectActiveUser);
  const isFetching = useSelector((state) => state.patient.isFetching);
  // const isAuthenticated = useSelector(selectIsAuthenticated);
  // function handleAuthState() {
  //   if (!firebase.auth().currentUser) {
  //     // console.log(firebase.auth().currentUser.displayName)
  //     history.push("/"); //push user back to login if they are not authenticated. This could also call the google auth api from here...
  //   }
  // }
  useEffect(() => {
    if (activeUser.credentials !== undefined) {
      document.getElementById("loader").style.display = "none";
      document.getElementById("authLayout").style.display = "block";
    }
  }, [activeUser]);

  // const logOut = () => {
  //   firebase
  //     .auth()
  //     .signOut()
  //     .then(() => {
  //       dispatch(logout());
  //       history.push("/");
  //     })
  //     .catch((error) => {
  //       toast.warning(error);
  //     });
  // };

  return (
    <>
      <div id="loader"></div>
      <div id="authLayout" className={styles.authLayout}>
        <div className={styles.topbar}>
          <div className={styles.patientInfo}>
            {patient.firstName ? (
              <div>
                {patient.lastName}, {patient.firstName}
              </div>
            ) : (
              <div>Select a Patient</div>
            )}
          </div>
          <div className={styles.spacer}></div>
          <div className={styles.activeUserBanner}>
            <img
              className={styles.profileThumb}
              alt=""
              src={activeUser.photoURL}
            />
            <div className={styles.nameCompenent}>
              <ConditionalComponent
                type="div"
                loading="Connecting to Google Cloud Healthcare "
                value={`${activeUser.displayName}`}
                className={styles.userName}
              ></ConditionalComponent>
              <div
                className={styles.logoutButton}
                type="button"
                onClick={logout}
              >
                logout
              </div>
              <small
                className={styles.practitionerId}
              >{`Practitioner id: ${activeUser.practitionerId}`}</small>
            </div>
          </div>
        </div>
        <div className={styles.header}>
          <LinkContainer exact to="/home">
            <img className={styles.bannerImage} alt="" src={bannerImage}></img>
          </LinkContainer>
        </div>
        <div className="container-fluid">
          <div className="row wrapper h-100 flex-column flex-md-row">
            <div className={styles.sansTop}>
              <Navbar collapseOnSelect expand="md" className={styles.sidenav}>
                <Navbar.Brand className={styles.brand}>
                  <img
                    className={styles.bannerImage}
                    alt=""
                    src={bannerImage}
                  ></img>
                </Navbar.Brand>
                <Navbar.Text>
                  <LinkContainer exact to="/home">
                    <img
                      className={styles.profileThumbNav}
                      alt=""
                      src={activeUser.photoURL}
                    />
                  </LinkContainer>
                </Navbar.Text>
                <Navbar.Text>
                  <div className={styles.patientInfoNav}>
                    {patient.firstName ? (
                      <div>
                        {patient.lastName}, {patient.firstName}
                      </div>
                    ) : (
                      <div>Select a Patient</div>
                    )}
                  </div>
                </Navbar.Text>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="m-0 p-0  ">
                    <div className="flex-column navbar-nav  w-100 ">
                      <LinkContainer className="p-2 h-100" to="/patientSearch">
                        <Nav.Link>Select Patient</Nav.Link>
                      </LinkContainer>

                      <hr className="p-0 m-2"></hr>

                      <LinkContainer className="p-2 h-100" to="/patient">
                        <Nav.Link className="h-100">Order Medications</Nav.Link>
                      </LinkContainer>

                      <hr className="p-0 m-2"></hr>

                      <LinkContainer className="p-2 h-100" to="/interactions">
                        <Nav.Link className="h-100">
                          Check Interactions
                        </Nav.Link>
                      </LinkContainer>

                      <hr className="p-0 m-2"></hr>
                      <LinkContainer className="p-2 h-100" exact to="/home">
                        <Nav.Link className="h-100">Home</Nav.Link>
                      </LinkContainer>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
            {isFetching ? <div className={styles.loadingBorder}></div> : <></>}
            <div className={styles.main}>{children}</div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}
export default AuthLayout;
