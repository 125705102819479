import React from "react";
import { Route } from "react-router-dom";

function AppRoute({
  logout,
  firebase,
  component: Component,
  layout: Layout,
  path,
  statusCode,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout logout={logout} firebase={firebase}>
          <Component logout={logout} firebase={firebase} {...props} />
        </Layout>
      )}
    />
  );
}

export default AppRoute;
