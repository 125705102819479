import React from "react";
import bg from "../../assets/FHIRScanLogo1.svg";
import styles from "./LoginPage.Module.scss";
import firebase from "firebase/app";
import "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  firstTimeAction,
  createPractitionerAction,
} from "../accounts/accountSlice";
import { useHistory } from "react-router-dom";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
function LoginPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.account.isFetching);
  // function handleLogin(user) {
  //   dispatch(login(user));
  // }

  const handleNewUser = (authResult) => {
    dispatch(createPractitionerAction(authResult.user));
    dispatch(firstTimeAction(true));
    //  handleLogin(authResult.user);
    history.push("/home");
  };
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/home",

    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // scopes: ["https://www.googleapis.com/auth/cloud-platform"],
      },

      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // scopes: ["https://www.googleapis.com/auth/cloud-platform"], //this requests scopes for the Client to use, it does not grant the User access to these scopes!
        customParameters: {
          prompt: "select_account",
        },
      },

      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: ["public_profile"],
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in....needed false with React
      signInSuccessWithAuthResult: (authResult) => {
        if (authResult.additionalUserInfo.isNewUser) {
          handleNewUser(authResult);
        }

        return false;
      },
    },
  };
  return (
    <>
      {isFetching ? (
        <div id="loader"></div>
      ) : (
        <div className={styles.loginContainer}>
          <div className={styles.topLogo}>
            <div className="shadow-lg py-4">
              <div className="card-body p-0 text-center">
                <img alt="Logo" src={bg}></img>
              </div>
            </div>
          </div>
          <div className="card shadow-lg o-hidden border-0 ">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-5 d-none d-lg-flex">
                  <div
                    className={styles.imageHolder}
                    style={{
                      backgroundImage: `url(${bg})`,
                    }}
                  ></div>
                </div>
                <div className="col-lg-7">
                  <div className="p-5">
                    <div className="text-center">
                      <h4 className="text-dark mb-4">
                        Choose Authentication Method
                      </h4>
                      <hr />
                    </div>
                    <StyledFirebaseAuth
                      uiConfig={uiConfig}
                      firebaseAuth={props.firebase.auth()}
                    />

                    <hr />
                    {/* <Formik
                    initialValues={{
                      password: "",
                      email: "",
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                      // same shape as initial values
                      console.log(values);
                    }}
                    >
                    {({ errors, touched }) => (
                      <Form className="user">
                      <div className="form-group">
                      <Field
                      className="form-control form-control-user"
                      name="email"
                      type="email"
                      placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div className={styles.textRed}>
                        <i className="fa fa-exclamation-circle"></i>{" "}
                        {errors.email}
                        </div>
                        ) : null}
                        </div>
                        <div className="form-group">
                        <Field
                        className="form-control form-control-user"
                        type="password"
                        name="password"
                        placeholder="Password"
                        />
                        {errors.password && touched.password ? (
                          <div className={styles.textRed}>
                          <i className="fa fa-exclamation-circle"></i>{" "}
                          {errors.password}
                          </div>
                          ) : null}
                          </div>
                          <button
                          className="btn btn-primary btn-block text-white btn-user"
                          type="button"
                          >
                          Submit
                          </button>
                          <hr />
                          
                          </Form>
                          )}
                        </Formik> */}
                    {/* <button
                    className="btn btn-outline-info btn-block "
                    type="button"
                    >
                    Create Account
                    </button>
                    <div className="text-center">
                    <a className="small" href="forgot-password.html">
                    Forgot Password?
                    </a>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default LoginPage;
