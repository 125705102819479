import React from "react";
import "./stylesheets/app.css";
import { Switch, useHistory } from "react-router-dom";
import AppRoute from "./AppRoute";
import UnauthLayout from "./Layouts/UnauthLayout";
import AuthLayout from "./Layouts/AuthLayout";
import LoginPage from "./features/accounts/LoginPage";
import AdminPage from "./app/AdminPage";
import SignUpPage from "./features/accounts/SignUpPage";
import HomePage from "./features/accounts/HomePage";
import PatientSearchPage from "./features/patients/PatientSearchPage";
import PatientPage from "./features/patients/PatientPage";
import InteractionsPage from "./features/patients/InteractionsPage";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "./app/FirebaseConfig";
import "firebase/analytics";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { login, logout } from "./features/accounts/accountSlice";

function App() {
  const firebaseApp =
    firebase.apps.length === 0
      ? firebase.initializeApp(firebaseConfig)
      : firebase;

  const { push } = useHistory();
  const dispatch = useDispatch();
  firebase.analytics();
  useEffect(() => {
    async function handleLogin(user) {
      return dispatch(login(user));
    }
    firebaseApp.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(async (user) => {
        if (user) {
          await handleLogin(user);
          push("/home");
        }
      });
    return () => {
      firebaseApp.unregisterAuthObserver();
    };
  }, [firebaseApp, dispatch, push]);
  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout());
        push("/");
      })
      .catch((error) => {
        toast.warning(error);
      });
  };
  return (
    <Switch>
      <AppRoute
        firebase={firebaseApp}
        layout={UnauthLayout}
        exact
        path="/"
        component={LoginPage}
      />
      <AppRoute
        layout={UnauthLayout}
        exact
        path="/signup"
        component={SignUpPage}
      />

      <AppRoute
        logout={logOut}
        layout={AuthLayout}
        exact
        path="/admin"
        component={AdminPage}
      />
      <AppRoute
        logout={logOut}
        layout={AuthLayout}
        exact
        path="/home"
        component={HomePage}
      />
      <AppRoute
        logout={logOut}
        layout={AuthLayout}
        exact
        path="/patientSearch"
        component={PatientSearchPage}
      />
      <AppRoute
        logout={logOut}
        layout={AuthLayout}
        path="/patient"
        component={PatientPage}
      />
      <AppRoute
        logout={logOut}
        layout={AuthLayout}
        path="/interactions"
        component={InteractionsPage}
      />
    </Switch>
  );
}
export default App;
