// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAOF8V-egZF2QKYH2Vu587sfuDcau_3i6c",
  authDomain: "mystic-sound-280300.firebaseapp.com",
  projectId: "mystic-sound-280300",
  storageBucket: "mystic-sound-280300.appspot.com",
  messagingSenderId: "152910781679",
  appId: "1:152910781679:web:16b580f52529c0ac5c0f1a",
  measurementId: "G-HEK1M0F1LP",
};
