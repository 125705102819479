import axios from "axios";
// import Result from "../../Result.js";
import { ServiceBase } from "../../ServiceBase.js";

// const url = process.env.API_URL + "medications/";
export default class MedicationService extends ServiceBase {
  constructor() {
    super();
    this.url = this.baseUrl + "/api/Medication/";
  }
  async getMedications(id) {
    const response = await axios(`${this.url}${id}`);

    return response.data;
  }

  async getInteractions(id) {
    let result;
    console.log(`id ${id}`);
    if (id == null) {
      result = "Please Select a Patient to view interactions";
    } else {
      result = await this.requestJson({
        url: `${this.url}GetInteractions/${id}`,
        method: "GET",
      });
    }

    return result;
  }
  async getInteractionsWithCurrent(id, currentMed) {
    const result = await this.requestJson({
      url: `${this.url}InteractionsWithCurrent/${id}`,
      method: "POST",
      data: {
        name: currentMed.name,
        rxcui: currentMed.rxcui,
      },
    });

    return result;
  }
  async orderMedication(id, practitionerId, currentMed) {
    const result = await this.requestJson({
      url: `${this.url}OrderMedication/${id}`,
      method: "POST",
      data: {
        name: currentMed.name,
        rxcui: currentMed.rxcui,
        practitionerId: practitionerId,
      },
    });

    return result;
  }
}
