import Result from "./Result.js";
import axios from "axios";
// import { transformUrl } from "domain-wait";
import queryString from "query-string";
import { showErrors } from "./utilities.js";
// import SessionManager from "./session";

/**
 * Represents base class of the isomorphic service.
 */
export class ServiceBase {
  constructor() {
    this.baseUrl =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_URL
        : process.env.REACT_APP_DEV_API_URL;
    console.log(`from super ctor${this.baseUrl}`);
  }

  async requestJson(opts) {
    var axiosResult = null;
    var result = null;

    axios.defaults.withCredentials = true;
    // opts.url = transformUrl(opts.url); // Allow requests also for the Node.

    var processQuery = (url, data) => {
      if (data) {
        return `${url}?${queryString.stringify(data)}`;
      }
      return url;
    };

    let axiosRequestConfig;

    // if (isNode()) {

    //     const ssrSessionData = SessionManager.getSessionContext().ssr;
    //     const { cookie } = ssrSessionData;

    let optionalHeaders =
      opts.opts == null || opts.opts.length === 0 ? {} : opts.opts;

    axiosRequestConfig = {
      headers: Object.assign(optionalHeaders, {
        withCredentials: true,
      }),
    };

    try {
      switch (opts.method) {
        case "GET":
          axiosResult = await axios.get(
            processQuery(opts.url, opts.data),
            axiosRequestConfig
          );
          break;
        case "POST":
          axiosResult = await axios.post(
            opts.url,
            opts.data,
            axiosRequestConfig
          );
          break;
        case "PUT":
          axiosResult = await axios.put(
            opts.url,
            opts.data,
            axiosRequestConfig
          );
          break;
        case "PATCH":
          axiosResult = await axios.patch(
            opts.url,
            opts.data,
            axiosRequestConfig
          );
          break;
        case "DELETE":
          axiosResult = await axios.delete(
            processQuery(opts.url, opts.data),
            axiosRequestConfig
          );
          break;
        default:
          break;
      }
      result = new Result(axiosResult.data.value, ...axiosResult.data.errors);
    } catch (error) {
      result = new Result(null, error.message);
    }

    if (result.hasErrors) {
      showErrors(...result.errors);
    }

    return result;
  }
}
