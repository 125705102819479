import axios from "axios";
export default class RxnavService {
  baseUrl = "https://rxnav.nlm.nih.gov/REST";
  async getMedicationSuggestions() {
    const response = await axios
      .get("https://rxnav.nlm.nih.gov/REST/Prescribe/displaynames.json", {
        withCredentials: false,
      })
      .then((response) => response.data);
    return response;
  }
  async getRxcui(name) {
    const response = await axios
      .get(`https://rxnav.nlm.nih.gov/REST/rxcui.json?name=${name}`, {
        withCredentials: false,
      })
      .then((response) => response.data);
    return response;
  }
  async getMedication(term) {
    const rxcui = await axios
      .get(`https://rxnav.nlm.nih.gov/REST/Prescribe/drugs.json?name=${term}`, {
        withCredentials: false,
      })
      .then((response) => response.data.drugGroup);

    return rxcui;
  }
}
