import React from "react";

class ConditionalComponent extends React.Component {
  
  render() {
    const e = React.createElement;
    return this.props.value == null || this.props.value.length === 0
      ? e(this.props.type, {className: this.props.className}, this.props.loading)
      : e(this.props.type, {className: this.props.className}, this.props.value);
  }
}

export default ConditionalComponent;
