import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import PatientService from "./PatientService";
import MedicationService from "./MedicationService";
export const patientSlice = createSlice({
  name: "patient",
  initialState: {
    isFetching: false,
    patientPages: [],
    firstLink: "",
    lastLink: "",
    nextLink: "",
    previousLink: "",
    total: 0,
    activePatient: {},
    interactions: [],
    interactionsWithCurrent: [],
    medications: [],
    activeMed: {},
  },
  reducers: {
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setPatients: (state, action) => {
      state.patientPages = action.payload.patients;
      state.firstLink = action.payload.firstLink;
      state.lastLink = action.payload.lastLink;
      state.nextLink = action.payload.nextLink;
      state.previousLink = action.payload.previousLink;
      state.total = action.payload.total;
    },
    setInteractions: (state, action) => {
      state.interactions = action.payload;
    },
    setInteractionsWithCurrent: (state, action) => {
      state.interactionsWithCurrent = action.payload;
    },
    setMedications: (state, action) => {
      state.medications = action.payload;
    },
    setActivePatient: (state, action) => {
      state.activePatient = action.payload;
    },
    setActiveMed: (state, action) => {
      state.activeMed = action.payload;
    },
    setPatientPages: (state, action) => {
      state.patientPages = action.payload;
    },
  },
});

export const {
  setFetching,
  setPatients,
  setInteractions,
  setMedications,
  setInteractionsWithCurrent,
  setActivePatient,
  setActiveMed,
  setPatientPages,
} = patientSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const setPatientAction = (patient) => async (dispatch) => {
  dispatch(setInteractions([]));
  dispatch(setInteractionsWithCurrent([]));
  dispatch(setMedications([]));
  await dispatch(setActivePatient(patient));
  return patient;
};

export const searchAction = (limitTo, firstName) => async (dispatch) => {
  await dispatch(setFetching(true));
  const service = new PatientService();

  const response = await service.search(limitTo, firstName);
  if (!response.hasErrors) {
    await dispatch(setPatients(response.value));
  }

  await dispatch(setFetching(false));

  return response;
};

export const loadNextPatientsAction = (nextLink, count) => (dispatch) => {
  dispatch(setFetching(true));

  const service = new PatientService();

  const result = service
    .nextPatients(nextLink, count)
    .then((patientList) => {
      dispatch(setPatients(patientList.value));
    })
    .catch((error) => {
      throw error;
    });
  dispatch(setFetching(false));
  return result;
};
export const loadPreviousPatientsAction = (previousLink, count) => (
  dispatch
) => {
  dispatch(setFetching(true));

  const service = new PatientService();

  const result = service
    .previousPatients(previousLink, count)
    .then((patientList) => {
      dispatch(setPatients(patientList.value));
    })
    .catch((error) => {
      throw error;
    });
  dispatch(setFetching(false));

  return result;
};

export const setActiveMedAction = (med) => async (dispatch) => {
  await dispatch(setActiveMed(med));
  await dispatch(setInteractionsWithCurrent([]));
};

export const setPatientPagesAction = (perPage) => async (
  dispatch,
  getState
) => {
  const paginate = (perPage, arrarr) => {
    const allMembers = arrarr.flat();
    const pages = Math.ceil(allMembers.length / perPage);
    const result = [];
    for (let i = 0; i < pages; i++) {
      result.push(allMembers.slice(i * perPage, i * perPage + perPage));
    }
    return result;
  };
  let allPatients = await getState().patient.patientPages;

  await dispatch(setPatientPages(paginate(perPage, allPatients)));
};

export const getInteractionsAction = (id) => async (dispatch) => {
  dispatch(setFetching(true));
  let result;
  if (id == null) {
    result = ["Please select a patient to View interactions"];
    dispatch(setInteractions(result));
  } else {
    const service = new MedicationService();

    result = await service.getInteractions(id);

    if (!result.hasErrors) {
      dispatch(setInteractions(result.value));
    }
  }
  dispatch(setFetching(false));

  return result;
};
export const getInteractionsWithCurrentAction = (id, current) => async (
  dispatch
) => {
  dispatch(setFetching(true));
  const service = new MedicationService();

  const result = await service.getInteractionsWithCurrent(id, current);

  if (!result.hasErrors) {
    dispatch(setInteractionsWithCurrent(result.value));
  }

  dispatch(setFetching(false));

  return result;
};
export const getActivePatientMedsAction = () => async (dispatch, getState) => {
  await dispatch(setFetching(true));
  let result;
  const id = getState().patient.activePatient.id;
  if (id == null) {
    result = ["Please select a patient to order medications"];
    dispatch(setMedications(result));
  } else {
    const service = new MedicationService();

    result = await service.getMedications(id);

    if (!result.hasErrors) {
      await dispatch(setMedications(result.value));
    }
  }
  dispatch(setFetching(false));

  return result;
};

export const orderActiveMedicationAction = () => async (dispatch, getState) => {
  const activeMed = getState().patient.activeMed;
  const id = getState().patient.activePatient.id;
  const practitionerId = getState().account.activeUser.practitionerId;
  await dispatch(setFetching(true));

  const service = new MedicationService();
  const orderResult = await service.orderMedication(
    id,
    practitionerId,
    activeMed
  );
  if (!orderResult.hasErrors) {
    toast.success(<h4>Order Complete</h4>, {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
    const result = await service.getMedications(id);

    await dispatch(setMedications(result.value));

    await dispatch(setActiveMed({}));
    await dispatch(setInteractionsWithCurrent([]));
  } else {
    toast.error(<h4>Go to Home page to upgrade credentials</h4>, {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  }
  await dispatch(setFetching(false));
  // return result;
};

export const selectPatients = (state) => state.patient.patientPages;
export const selectIsFetching = (state) => state.patient.isFetching;
export const selectActiveMedication = (state) => state.patient.activeMed;
export const selectMedications = (state) => state.patient.medications;
export const selectInteractions = (state) => state.patient.interactions;
export const selectInteractionsWithCurrent = (state) =>
  state.patient.interactionsWithCurrent;
export const selectActivePatient = (state) => state.patient.activePatient;
export const selectTotalPatients = (state) => state.patient.total;
export const selectFirstLink = (state) => state.patient.firstLink;
export const selectLastLink = (state) => state.patient.lastLink;
export const selectNextLink = (state) => state.patient.nextLink;
export const selectPreviousLink = (state) => state.patient.previousLink;
export default patientSlice.reducer;
