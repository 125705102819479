import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
} from "react";

function AutoCompleteInput({ termList, propertyFunc, placholder, begin }, ref) {
  const searchAutoInputRef = useRef(null);
  useImperativeHandle(ref, () => ({
    value: () => searchInput,
    change: (value) => handleSearchInputChange(value),
  }));
  const datalistRef = useRef();

  const medRef = useRef(null);
  const [inputHasFocus, setInputFocus] = useState(false);
  const [searchInput, handleSearchInputChange] = useState("");
  const handleOptionClick = (term) => {
    handleSearchInputChange(term);
  };
  const handleArrowDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      datalistRef.current.firstChild.focus();
    }
  };

  const inputFocusStyles = {
    zIndex: 100,
    overflowX: "hidden",
    display: "block",
    width:
      searchAutoInputRef.current != null
        ? searchAutoInputRef.current.offsetWidth + "px"
        : "0px",
    // left:
    //   searchAutoInputRef.current != null
    //     ? searchAutoInputRef.current.getBoundingClientRect().left + "px"
    //     : "0px",
    // top:
    //   searchAutoInputRef.current != null
    //     ? searchAutoInputRef.current.getBoundingClientRect().top +
    //       searchAutoInputRef.current.offsetHeight +
    //       "px"
    //     : "0px",
    maxHeight: "150px",
  };
  
  var propList = propertyFunc !== undefined ? propertyFunc() : termList;

  const arrarr = propList.filter(
    (el) => el.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
  );

  const sortedList =
    searchInput.length > begin
      ? arrarr.filter(
          (term) => term[0].toLowerCase() === searchInput[0].toLowerCase()
        )
      : [];
  //using two arrays here let the terms that begin with the same letter as the search term be place first, followed by others that match
  const renderList =
    searchInput.length > begin 
      ? sortedList.concat(
          ...arrarr.filter(
            (a) => a[0].toLowerCase() !== searchInput[0].toLowerCase()
          )
        )
      : [];
  function RenderListItems({
    searchAutoInputRef,
    // medRef,
    setInputFocus,
    handleOptionClick,
  }) {
    const handleKeyPressList = (event) => {
      switch (event.key) {
        case "Enter":
          event.preventDefault();
          handleOptionClick(event.target.value);
          setInputFocus(false);
          searchAutoInputRef.current.focus();
          break;
        case "ArrowDown":
          if (event.target.nextSibling != null) {
            event.preventDefault();
            event.target.nextSibling.focus();
          }
          break;
        case "ArrowUp":
          if (event.target.previousSibling != null) {
            event.preventDefault();
            event.target.previousSibling.focus();
          } else {
            setInputFocus(false);
            searchAutoInputRef.current.focus();
          }
          break;
        default:
          break;
      }
    };
    return renderList.map((term) => (
      <option
        tabIndex="1"
        ref={medRef}
        key={term}
        // className={styles.autoCompleteItem}
        onClick={() => {
          handleOptionClick(term);
          setInputFocus(false);
        }}
        onKeyDown={handleKeyPressList}
      >
        {term}
      </option>
    ));
  }
  return (
    <>
      <input
        ref={searchAutoInputRef}
        type="search"
        id="input"
        className="form-control"
        placeholder={placholder}
        list=""
        autoComplete="off"
        onKeyDown={handleArrowDown}
        value={searchInput}
        onSubmit={() => handleSearchInputChange("")}
        onInput={(e) => {
          handleSearchInputChange(e.target.value);
          setInputFocus(true);
        }}
      />
      <datalist
        ref={datalistRef}
        id="datalist"
        style={inputHasFocus ? inputFocusStyles : {}}
      >
        <RenderListItems
          searchAutoInputRef={searchAutoInputRef}
          setInputFocus={setInputFocus}
          handleOptionClick={handleOptionClick}
        ></RenderListItems>
      </datalist>
    </>
  );
}

export default forwardRef(AutoCompleteInput);
