import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PatientList from "./PatientList";
import styles from "./PatientSearchPage.Module.scss";
import Pagination from "react-bootstrap/Pagination";
import {
  setPatientPagesAction,
  getInteractionsAction,
  // selectIsFetching,
  // selectFirstLink,
  // selectLastLink,
  selectNextLink,
  setPatientAction,
  searchAction,
  selectPatients,
  selectTotalPatients,
} from "./patientSlice";

const PatientSearchPage = () => {
  const [firstNameTerm, setFirstName] = useState("");
  // const [lastNameTerm, setLastName] = useState("");
  const [currentPage, setPage] = useState(1);
  const [limitTo, setLimit] = useState(10);
  const searchBar = useRef(null);
  const dispatch = useDispatch();
  const patientPages = useSelector(selectPatients);
  const totalPatients = useSelector(selectTotalPatients);
  // const firstLink = useSelector(selectFirstLink);
  const nextLink = useSelector(selectNextLink);
  // const previousLink = useSelector(selectPreviousLink);
  // const lastLink = useSelector(selectLastLink);
  // const isFetching = useSelector(selectIsFetching);
  const history = useHistory();
  const loadNext = (nextLink, count) => {
    // if (!!nextLink) {
    //   dispatch(loadNextPatientsAction(nextLink, count));
    setPage(currentPage + 1);
    // }
  };
  const loadPrevious = (previousLink, count) => {
    // if (!!previousLink) {
    //   dispatch(loadPreviousPatientsAction(previousLink, count));
    setPage(currentPage - 1);
    // }
  };

  const renderPatientList = () => (
    <PatientList
      id="dataTable"
      style={{
        transitionTimeingFunction: "ease-in",
        transition: { width: "2s", linear: "1s" },
      }}
      // className={styles.patientList}
      patients={patientPages.length > 0 ? patientPages[currentPage - 1] : []}
      history={history}
      dispatch={dispatch}
      setInteractions={getInteractionsAction}
      setPatient={setPatientAction}
    />
  );

  const element = [];
  const pages = Math.ceil(totalPatients / limitTo);

  for (let index = 0; index < pages; index++) {
    element.push(
      <Pagination.Item
        onClick={() => setPage(index + 1)}
        key={index}
        active={currentPage === index + 1}
      >
        {index + 1}
      </Pagination.Item>
    );
  }
  const setPaging = () => (
    <Pagination>
      {/* <Pagination.First disabled={!firstLink} /> */}
      <Pagination.Prev
        disabled={currentPage <= 1}
        onClick={() => loadPrevious("previousLink", limitTo)}
      />
      {element}
      <Pagination.Next
        disabled={currentPage >= pages}
        onClick={() => loadNext(nextLink, limitTo)}
      />
      {/* <Pagination.Last disabled={!lastLink} /> */}
    </Pagination>
  );
  async function changeLimitHandler(e) {
    function _() {
      setLimit(parseInt(e.target.value));
      setPage(1);
      dispatch(setPatientPagesAction(parseInt(e.target.value)));
    }
    return _();
  }

  return (
    <div className={styles.patientListCard}>
      <div className="row">
        <div className=" text-nowrap">
          <div
            id="dataTable_length"
            // className="dataTables_length"
            aria-controls="dataTable"
          >
            <label>
              Show 
              <select
                value={limitTo}
                className=" custom-select custom-select-sm"
                defaultValue={limitTo}
                onChange={async (event) => await changeLimitHandler(event)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </label>
          </div>
        </div>
      </div>
      <div ref={searchBar} className="row">
        <div className="col">
          <div className="card shadow mb-3">
            <div className="card-header py-1">
              <p className="text-primary m-0 font-weight-bold">
                Patient Search
              </p>
            </div>
            <div className="card-body pb-0">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(searchAction(limitTo, firstNameTerm));
                }}
              >
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="username">
                        <strong>Enter first or last name</strong>
                      </label>
                      <div
                        className="col text-md-right dataTables_filter"
                        id="dataTable_filter"
                      >
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            aria-controls="dataTable"
                            placeholder="Name"
                            onInput={(e) => setFirstName(e.target.value)}
                            onSubmit={(e) => {}}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onSubmit={(e) => {
                              dispatch(searchAction(limitTo, firstNameTerm));
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12"></div>
      <>{renderPatientList()}</>
      <div className="row">
        <div className="col-md-6 align-self-center">
          <p
            id="dataTable_info"
            className="dataTables_info"
            role="status"
            aria-live="polite"
          >
            {totalPatients > 0
              ? `Showing ${limitTo * (currentPage - 1) + 1} to ${
                  limitTo * currentPage > totalPatients
                    ? totalPatients
                    : limitTo * currentPage
                } of ${totalPatients}`
              : ""}
          </p>
        </div>
        <div className="col-md-6">{setPaging()}</div>
      </div>
    </div>
  );
};

export default PatientSearchPage;
