import React from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";
import styles from "./WelcomeModal.Module.scss";
import { selectActiveUser } from "./accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const WelcomeModal = (props) => {
  const practitionerId = useSelector(selectActiveUser).practitionerId;
  const nextIcon = (
    <span aria-hidden="false">
      <FontAwesomeIcon icon={faAngleRight} size="5x" />
    </span>
  );
  const previousIcon = (
    <span aria-hidden="false">
      <FontAwesomeIcon icon={faAngleLeft} size="5x" />
    </span>
  );
  return (
    <Modal
      {...props}
      dialogClassName={styles.modal90w}
      contentClassName={styles.modal90w}
      // size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h1>Welcome to FHIRscan</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>
        <h2>
          <i>This seems like your first time loging in</i>
        </h2>
        <Carousel
          // className={styles.modalBody}
          wrap={false}
          pause="hover"
          interval={null}
          nextIcon={nextIcon}
          prevIcon={previousIcon}
        >
          <Carousel.Item>
            <div className={styles.carouselItem}>
              <div>
                <p>
                  FHIRScan provides a medication prescribing application that
                  has drug-drug interaction detection capabilities, sourced from
                  the National Library of Medicine’s Unified Medical Language
                  System. This is achieved through RxNorm
                </p>
                <blockquote>
                  <p>
                    RxNorm provides normalized names for clinical drugs and
                    links its names to many of the drug vocabularies commonly
                    used in pharmacy management and drug interaction software,
                    including those of First Databank, Micromedex, and Gold
                    Standard Drug Database. By providing links between these
                    vocabularies, RxNorm can mediate messages between systems
                    not using the same software and vocabulary
                  </p>
                </blockquote>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className={styles.carouselItem}>
            <p>
              To provide a robust, performant, and secure ordering system, HL7’s FHIR{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.hl7.org/fhir/overview.html"
              >
                {" "}
                Fast Healthcare Interoperability Resources{" "}
              </a>{" "}
              specification is implemented through Google Cloud Healthcare,
              providing a fast, scalable, and encrypted platform.
            </p>
            <p>
              The data in this database was generated using
              <a
                target="_blank"
                rel="noreferrer"
                href="https://synthetichealth.github.io/synthea/"
              >
                {" "}
                Synthea{" "}
              </a>{" "}
              which generates healthcare data that is realistic, but not real.
            </p>
          </Carousel.Item>
          <Carousel.Item className={styles.carouselItem}>
            <p>
              In this demo, ordering privileges are controlled via accesss token
              tied to an assisgnable practitioner role "credential".
            </p>
            <p>
              Physician is the only role that can order medications, while
              nurses can check interactions and view the patients'
              medications.
            </p>
          </Carousel.Item>
          <Carousel.Item className={styles.carouselItem}>
            <p>
              A new{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.hl7.org/fhir/practitioner.html"
              >
                {" "}
                Practitioner resource{" "}
              </a>{" "}
              was created for you. You are now logged into the ordering system
              under Practitioner {practitionerId}
            </p>
            <p>
              You may grant yourself ordering privileges by changing your role
              to Physician on the Home page
            </p>
            <p>
              To order medications, use the search bar on the Order Medications
              tab. There you can search for ingredients, brands, and synonyms of
              branded and generic packs to find currently prescribable
              medications
            </p>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <button
          className="btn btn-primary"
          onClick={() => {
            props.onHide();
          }}
        >
          Continue to Home Page
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
