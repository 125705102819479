import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./MedicationModal.Module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getInteractionsWithCurrentAction,
  setActiveMedAction,
} from "./patientSlice";

const MedicationModal = (props) => {
  const dispatch = useDispatch();

  const patient = useSelector((state) => state.patient.activePatient);
  const [selectedMed, setMed] = useState({});
  const handleMed = (med) =>
    med == null || med.length === 0 ? (
      <tr>
        <td>No Prescribable drugs with this concept exist in RxNorm</td>
      </tr>
    ) : (
      med.map((a) => (
        <tr
          onClick={() => setMed({ rxcui: a.rxcui, name: a.name })}
          key={a.rxcui}
        >
          <td>{a.name}</td>
        </tr>
      ))
    );
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      //centered
      backdrop="static"
      keyboard={false}
      onExited={()=> setMed({})}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Medications
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Select a medication</h4>{" "}
        <table className={styles.medTable}>
          <tbody>{handleMed(props.med)}</tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <div>{selectedMed.name}</div>
        <button className="btn btn-info"
          onClick={() => {
            dispatch(setActiveMedAction(selectedMed));
            dispatch(
              getInteractionsWithCurrentAction(patient.id, selectedMed)
            );
            props.onHide();
            props.showInteractions();
          }}
        >
          Check Interactions 
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MedicationModal;
