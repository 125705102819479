import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import suggestions from "../../meds.json";
import styles from "./PatientPage.Module.scss";
import RxnavService from "./RxnavService";
import AutoCompleteComponent from "./AutoCompleteComponent";
import MedicationModal from "./MedicationModal";
import InteractionsModal from "./InteractionsModal";
import AutoCompleteInput from "../../AutoCompleteInput";

import {
  selectActivePatient,
  getActivePatientMedsAction,
} from "./patientSlice";

const PatientPage = ({ setInteractions }) => {
  const termList = suggestions.meds.displayTermsList.term;
  const medications = useSelector((state) => state.patient.medications);
  const renderRows = () => (
    <tbody>
      {medications.map((medication) => (
        <tr key={medication.resourceId}>
          <td>{medication.text}</td>
          <td>{medication.resourceId}</td>
          <td>{medication.prescriber}</td>
          <td>{medication.timeOrdered}</td>
        </tr>
      ))}
    </tbody>
  );

  const orderTable = (
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Medication Id</th>
          <th>Presciber</th>
          <th>Time Ordered</th>
        </tr>
      </thead>
      {renderRows()}
    </table>
  );
  const searchInputRef = useRef(null);
  const searchAutoInputRef = useRef(null);
  const medRef = useRef(null);
  const dispatch = useDispatch();
  const service = new RxnavService();
  const patient = useSelector(selectActivePatient);

  const [searchInput, handleSearchInputChange] = useState("");
  const [drugs, setDrug] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalInteractionsShow, setModalInteractionsShow] = useState(false);

  const [inputHasFocus, setInputFocus] = useState(false);
  // const handleRefreshMeds = async () => {
  //   dispatch(getActivePatientMedsAction());
  // };

  useEffect(() => {
    if (patient != null || patient.length !== 0) {
      dispatch(getActivePatientMedsAction());
    }
  }, [dispatch, patient]);
  const handleOptionClick = (term) => {
    handleSearchInputChange(term);
  };

  // const handleArrowDown = (event) => {
  // if (event.key === "ArrowDown") {
  //   event.preventDefault();
  //   medRef.current.focus();
  //   }
  // };
  const handleAutoSubmitOrder = async (event) => {
    event.preventDefault();
    let target = {};
    let arr = [];
    const result = await service.getMedication(
      searchAutoInputRef.current.value()
    );
    if (result.hasOwnProperty("conceptGroup")) {
      arr = result.conceptGroup //TODO: this can be null? Yes
        .map((p) => p.conceptProperties)
        .filter((c) => c !== undefined);
      arr = arr.flat();
      setDrug(arr);
    } else {
      if (result.hasOwnProperty("name")) {
        let singleRxcui = await service.getRxcui(result.name);
        target.rxcui = singleRxcui.idGroup.rxnormId[0]; //this is an array
        target.name = singleRxcui.idGroup.name;
        setDrug([target]);
      } else {
        setDrug([""]);
      }
    }
    setModalShow(true);
    searchAutoInputRef.current.change("");
  };
  // const handleSubmitOrder = async (event) => {
  //   event.preventDefault();
  //   const result = await service.getMedication(searchInput);
  //   const arr = result.conceptGroup //TODO: this can be null? Yes
  //     .map((p) => p.conceptProperties)
  //     .filter((c) => c !== undefined);
  //   handleDrug(arr);
  //   setModalShow(true);
  //   handleSearchInputChange("");
  // };

  return (
    <div className="container-fluid">
      <div className="row mb-0">
        <div className="col-lg-12">
          <div className="card shadow mb-3">
            <div className="card-header py-1">
              <p className="text-primary m-0 font-weight-bold">
                Order New Medication
              </p>
            </div>
            <div className="card-body">
              <form onSubmit={handleAutoSubmitOrder}>
                <div className="form-group">
                  <label htmlFor="input">
                    <strong>Medication Concept Search </strong>
                  </label>
                  <AutoCompleteInput
                    ref={searchAutoInputRef}
                    placholder="Search RxNorm Prescribable drugs"
                    // propertyFunc={(p) => p}
                    begin={2}
                    termList={termList}
                  ></AutoCompleteInput>
                  {/* <input
                    ref={searchInputRef}
                    type="search"
                    id="input"
                    className="form-control"
                    placeholder="Search RxNORM terms"
                    list=""
                    autoComplete="off"
                    onKeyDown={handleArrowDown}
                    value={searchInput}
                    onSubmit={handleSubmitOrder}
                    onInput={(e) => {
                      handleSearchInputChange(e.target.value);
                      setInputFocus(true);
                    }}
                  /> */}
                </div>
                <div className="form-group">
                  <button
                    // onClick={() => handleSubmitOrder(searchInput)}
                    className="btn btn-primary btn-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    // onClick={() => handleSubmitOrder(searchInput)}
                    className="btn btn-primary btn-sm"
                    type="reset"
                  >
                    Cancel
                  </button>
                </div>
              </form>
              <MedicationModal
                show={modalShow}
                showInteractions={() => setModalInteractionsShow(true)}
                onHide={() => {
                  setModalShow(false);
                }}
                med={drugs}
                // showInteractionsModal={setModalInteractionsShow}
              ></MedicationModal>
              <InteractionsModal
                show={modalInteractionsShow}
                onHide={() => {
                  setModalInteractionsShow(false);
                  // handleRefreshMeds();
                }}
              ></InteractionsModal>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card shadow mb-3">
            <div className="card-header py-1">
              <p className="text-primary m-0 font-weight-bold">Medications</p>
            </div>
            <div className={styles.medTable}>{orderTable}</div>
          </div>
        </div>
      </div>
      {
        <AutoCompleteComponent
          ref={medRef}
          termList={termList}
          searchInputRef={searchInputRef}
          searchInput={searchInput}
          inputHasFocus={inputHasFocus}
          setInputFocus={setInputFocus}
          handleOptionClick={handleOptionClick}
        ></AutoCompleteComponent>
      }
    </div>
  );
};

export default PatientPage;
