import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AccountService from "./AccountService";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    isFetching: false,
    isAuthenticated: false,
    activeUser: {},
    isFirstLogin: false,
  },
  reducers: {
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    logInUser: (state, action) => {
      if (action.payload) {
        state.activeUser = action.payload;
        state.isAuthenticated = true;
      }
    },

    updateCredential: (state, action) => {
      state.activeUser.credentials = state.activeUser.credentials.concat(
        ...action.payload
      );
    },

    firstTime: (state, action) => {
      state.isFirstLogin = action.payload;
    },
    setPractitionerId: (state, action) => {
      state.practitionerId = action.payload;
    },

    logOutUser: (state) => {
      state.activeUser = {};
      state.isAuthenticated = false;
    },
  },
});
export const {
  logInUser,
  firstTime,
  setPractitionerId,
  logOutUser,
  updateCredential,
  setFetching,
} = accountSlice.actions;

export const login = (user) => async (dispatch) => {
  // if (user != null) {
  await dispatch(setFetching(true));
  const token = await user.getIdToken(/* forceRefresh*/ true);
  const { displayName, photoURL, uid, email } = user;
  const service = new AccountService();

  const result = await service.loginPractitioner(token);
  if (!result.hasErrors) {
    const practitionerId = result.value.id;
    const credentials =
      result.value.credentials.length === 0
        ? ["No credentials on file"]
        : result.value.credentials;
    console.log(`${credentials} and ID ${practitionerId}`);

    await dispatch(
      logInUser({
        displayName,
        photoURL,
        uid,
        email,
        practitionerId,
        credentials,
      })
    );
    await dispatch(setFetching(false));
  } else {
    await dispatch(setFetching(false));
    return result.errors.map((e) => toast.warning(<h3>{`Error: ${e}`}</h3>));
  }
};

export const logout = () => async (dispatch) => {
  const service = new AccountService();

  await service.logoutPractitioner();
  await dispatch(logOutUser());
  return;
};
export const updateCrediential = (crediential, id) => async (dispacth) => {
  const service = new AccountService();

  const result = await service.updateCrediential(crediential, id);
  if (!result.hasErrors) {
    dispacth(updateCredential(result.value));
    toast.info(<h5>You must logout to propagate changes in credentials</h5>);
  }
};
export const createPractitionerAction = (user) => async (dispatch) => {
  const token = await user.getIdToken(/* forceRefresh*/ true);
  const { displayName, photoURL, uid, email } = user;
  const service = new AccountService();

  const result = await service.createPractitioner(displayName, email, uid, token);

  if (!result.hasErrors) {
    const { practitionerId } = result.data.value;
    console.log(`practitionerId created ${practitionerId}`);
    await dispatch(
      logInUser({ displayName, photoURL, uid, email, practitionerId })
    );
  }
  return result;
};

export const firstTimeAction = (isNewUser) => (dispatch) => {
  dispatch(firstTime(isNewUser));
  return isNewUser;
};

export const selectActiveUser = (state) => state.account.activeUser;
export const selectIsFetching = (state) => state.account.isFetching;
export const selectIsAuthenticated = (state) => state.account.isAuthenticated;
export const selectPractitionerId = (state) => state.account.practitionerId;
export const selectFirstLogin = (state) => state.account.isFirstLogin;

export default accountSlice.reducer;
