import React from "react";

function CrednetialsSelector({ termList, handelSelect, startingSelection }) {
    console.log(`Starting ${startingSelection.credentialName}`)
    return (<select
        className=" custom-select custom-select-sm"
        // value={selectedCredential.credentialName}
        onChange={(e) => {
            // console.log(`onChange fired vales ${e.target.value}`)
            handelSelect(e.target.value)
        }}>
        if (!startingSelection?.credentialName) {

            <option selected hidden key={100000} value={'default'} disabled>
                Choose a Credential
            </option>
        }
        {
            termList.map((c, index) => {
                console.log(`Lidt ${JSON.stringify(c)}`)
                return <option key={index} value={index}>
                    {c.credentialName}
                </option>

            })
        }

    </select >)

};

export default CrednetialsSelector;
