import React, { useEffect } from "react";
import styles from "./PatientList.Module.scss";
// import trimDisplay from "../../ElipsisSting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserInjured,
  faUser,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// import {
//   setInteractions,
//   setMedications,
//   setActivePatient,
// } from "./patientSlice";
// import { useDispatch,  } from "react-redux";
const PatientList = ({ patients, history, dispatch, setMeds, setPatient }) => {
  // const [activePatient, setActivePatient] = useState(null);

  const faList = [faUserInjured, faUserLock, faUser];
  const handleClickActivePatient = async (patient) => {
    dispatch(setPatient(patient)); //making this syncronous gave the store enough time to load the state. otherwise, active user was undefined

    history.push(`patient/${patient.id}`);
  };
  const genIcon = () => faList[Math.floor(Math.random() * 2.5)];

  // const handleRowClick = (patient) => {
  //   setActivePatient(patient);
  // };
  useEffect(() => {
    if (patients.length > 0) {
      console.log(`patientList patients ${patients}`);
      // document.getElementById("loader").style.display = "none";
      document.getElementById("patientList").style.display = "block";
      // document.getElementsByClassName(styles.patientList).style.display = "block";
    }
  }, [patients]);
  const renderTooltip = (id) => <Tooltip id="button-tooltip">{id}</Tooltip>;
  const renderRows = () => (
    <tbody>
      {patients.map((patient) => (
        <tr className={styles.medButtonRow} key={patient.id}>
          <td>
            <FontAwesomeIcon icon={genIcon()}></FontAwesomeIcon>
            {" | "}
            {patient.firstName}
          </td>
          <td>{patient.lastName}</td>
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 150 }}
            overlay={renderTooltip(patient.id)}
          >
            <td>{patient.id}</td>
          </OverlayTrigger>
          <td>
            <button
              type="button"
              className={styles.medButton}
              onClick={() => handleClickActivePatient(patient)}
            >
              Select
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      {/* <div id="loader"></div> */}
      <div
        className={styles.patientList}
        // id="dataTable"
        id="patientList"
        role="grid"
        aria-describedby="dataTable_info"
      >
        <table className="table my-0">
          <thead>
            <tr>
              <th className={styles.headers}>First Name</th>
              <th className={styles.headers}>Last Name</th>
              <th className={styles.headers}>ID</th>
            </tr>
          </thead>
          {renderRows()}
          <tfoot></tfoot>
        </table>
      </div>
    </>
  );
};
export default PatientList;
