import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import styles from "./InteractionsPage.Module.scss";
import { useSelector, useDispatch } from "react-redux";
import { getInteractionsAction, selectActivePatient } from "./patientSlice";

function InteractionsPage(props) {
  const dispatch = useDispatch();
  const patient = useSelector(selectActivePatient);
  const interactions = useSelector((state) => state.patient.interactions);

  useEffect(() => {
    if (patient != null || patient.length !== 0) {
      dispatch(getInteractionsAction(patient.id));
    }
  }, [dispatch, patient]);
  const handleInteractions = () =>
    interactions.length > 1 ? (
      interactions.map((i) => (
        <Card className={styles.interactionCard} key={i.interactionId}>
          <div>
            Interaction:{" "}
            <strong className={styles.medName}>
              {i.medicationPair.drug1.displayName}
            </strong>{" "}
            +{" "}
            <strong className={styles.medName}>
              {i.medicationPair.drug2.displayName}
            </strong>
          </div>
          {}
          {i.drugInteractionDetails.map((details) => (
            <>
              <Accordion.Toggle
                as={Card.Header}
                id={i.interactionId}
                eventKey={details.interactionAssertion}
                className={styles.accordianHeader}
              >
                {" "}
                {details.interactionAssertion}
              </Accordion.Toggle>
              <Accordion.Collapse
                className={styles.accordianCollapse}
                eventKey={details.interactionAssertion}
              >
                <Card.Body className={styles.innerInteractionCard}>
                  {details.description}

                  <div className={styles.interactionRow}>
                    {" References: "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={details.linkList[0].link}
                    >
                      {details.linkList[0].display}
                    </a>
                    {"    ||    "}

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={details.linkList[1].link}
                    >
                      {details.linkList[1].display}
                    </a>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </>
          ))}{" "}
        </Card>
      ))
    ) : (
      <h1>{interactions}</h1>
    );
  return (
    <>
      <div className={styles.interactionsPageMain}>
        <div className={styles.accordionDiv}>
          <Accordion id="accordionFlushExample">
            {handleInteractions()}
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default InteractionsPage;
