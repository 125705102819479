import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import patientReducer from "../features/patients/patientSlice";
import accountReducer from "../features/accounts/accountSlice";

// configureStore(): wraps createStore to provide simplified configuration
// options and good defaults. It can automatically combine your slice
// reducers, adds whatever Redux middleware you supply, includes redux
// -thunk by default, and enables use of the Redux DevTools Extension.

const combinedReducer = combineReducers({
  patient: patientReducer,
  account: accountReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "account/logOutUser") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});
