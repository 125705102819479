// import Result from "../../Result";
import { ServiceBase } from "../../ServiceBase";
// const url = process.env.API_URL + "patients/";
// const baseUrl =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_PROD_API_URL
//     : process.env.REACT_APP_DEV_API_URL;
export default class AccountService extends ServiceBase {
  constructor() {
    super();
    this.url = this.baseUrl + "/api/account/";
    console.log(`BASEURL from account ctor${this.baseUrl}`);
    console.log(`URL from account ctor${this.url}`);
  }

  async createPractitioner(name, email, uid, token) {
    const headers = { Authorization: `Bearer ${token}` };
    const result =
      await this.requestJson({
        method: "POST",
        url: `${this.url}CreatePractitioner`,
        data: {
          name: name,
          email: email,
          uid: uid,
        },
        opts: headers,
      });
    return result;
  }
  async updateCrediential(cred, id) {
    const result = await this.requestJson({
      method: "POST",
      url: `${this.url}UpdateCredential`,
      data: {
        credentialName: cred.credentialName,
        credentialCode: cred.credentialCode,
        id: id,
      },
    });
    return result;
  }
  async loginPractitioner(token) {
    const headers = { Authorization: `Bearer ${token}` };
    const result = await this.requestJson({
      method: "POST",
      url: `${this.url}LoginPractitioner`,
      // data: { token: token },
      opts: headers,
    });
    return result;
  }
  async logoutPractitioner() {
    // const headers = { Authorization: `Bearer ${token}` };
    const result = await this.requestJson({
      method: "POST",
      url: `${this.url}LogoutPractitioner`,
      // data: { token: token },
      // opts: headers,
    });
    return result;
  }
}
