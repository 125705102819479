import { Field, Form, Formik } from "formik";
import React from "react";
import { SignupSchema } from "./SignUpSchema";
import bg from "../../assets/FHIRScanLogoSM.png";
import styles from "./Accounts.Module.scss";

const SignUpPage = (props) => {
  return (
    <div>
      <div className="container">
        <div className="card shadow-lg o-hidden border-0 my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-5 d-none d-lg-flex">
                <div
                  className={styles.imageHolder}
                  style={{
                    backgroundImage: `url(${bg})`,
                  }}
                ></div>
              </div>
              <div className="col-lg-7">
                <div className="p-5">
                  <div className="text-center">
                    <h4 className="text-dark mb-4">Create an Account!</h4>
                  </div>

                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      password: "",
                      passwordRepeat: "",
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                      // same shape as initial values
                      console.log(values);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form className="user">
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <Field
                              className="form-control form-control-user"
                              name="firstName"
                              placeholder="First Name"
                            />
                            {errors.firstName && touched.firstName ? (
                              <div className={styles.textRed}>
                                {errors.firstName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-6">
                            <Field
                              className="form-control form-control-user"
                              name="lastName"
                              placeholder="Last Name"
                            />
                            {errors.lastName && touched.lastName ? (
                              <div className={styles.textRed}>
                                {errors.lastName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group">
                          <Field
                            className="form-control form-control-user"
                            name="email"
                            type="email"
                            placeholder="Email"
                          />
                          {errors.email && touched.email ? (
                            <div className={styles.textRed}>{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <Field
                              className="form-control form-control-user"
                              name="password"
                              placeholder="Create a Password"
                            />
                            {errors.password && touched.password ? (
                              <div className={styles.textRed}>
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-6">
                            <Field
                              className="form-control form-control-user"
                              type="password"
                              name="passwordRepeat"
                              placeholder="Confirm Password"
                            />
                            {errors.passwordRepeat && touched.passwordRepeat ? (
                              <div className={styles.textRed}>
                                {errors.passwordRepeat}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <button className="primary btn btn-primary btn-block text-white">
                          Register Account
                        </button>
                        {/* <hr /><a className="btn btn-primary btn-block text-white btn-google btn-user" role="button"><i className="fa fa-google"></i>  Register with Google</a><a className="btn btn-primary btn-block text-white btn-facebook btn-user" role="button"><i className="fa fa-facebook-f"></i>  Register with Facebook</a>
                                                <hr /> */}
                      </Form>
                    )}
                  </Formik>
                  <div className="text-center">
                    {/* <a className="small "type="button" onClick={()=> history.push(`/`)}>Already have an account? Login!</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpPage;
