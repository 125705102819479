import * as Yup from 'yup';

export const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .max(50, 'Too Long!')
        .required('Required'),
    password: Yup.string()
        .min(7, 'Must be at least 7 characters')
        .max(50, 'Too Long!')
        .required('Required'),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});
