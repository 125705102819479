import React from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import styles from "./InteractionsModal.Module.scss";
import { useSelector, useDispatch } from "react-redux";
import { orderActiveMedicationAction } from "./patientSlice";
import { toast } from "react-toastify";

const InteractionsModal = (props) => {
  const isFetching = useSelector((state) => state.patient.isFetching);
  const dispatch = useDispatch();
  const interactionsWithCurrent = useSelector(
    (state) => state.patient.interactionsWithCurrent
  );
  const Undo = ({ onUndo, closeToast }) => {
    const handleClick = () => {
      onUndo();
      closeToast();
    };

    return (
      <div>
        <h4>Order pending</h4>
        <button className="btn btn-sm btn-secondary" onClick={handleClick}>
          Cancel Order
        </button>
      </div>
    );
  };

  const handleOrderMedication = async () => {
    dispatch(orderActiveMedicationAction());

    props.onHide();
  };
  let orderDelegate = null;

  const renderUndoToast = () =>
    toast.info(
      <Undo
        onUndo={() => (orderDelegate = () => console.log("Clean delegate"))}
      />,
      {
        onClose: () => {
          orderDelegate();
        },
      }
    );
  function assignOrderDelegate() {
    orderDelegate = handleOrderMedication;
    renderUndoToast();
  }
  const handleInteractions = () =>
    interactionsWithCurrent.length > 0 ? (
      interactionsWithCurrent.map((i) => (
        <Card className={styles.interactionCard} key={i.interactionId}>
          <div>
            Interaction:{" "}
            <strong className={styles.medName}>
              {i.medicationPair.drug1.displayName}
            </strong>{" "}
            +{" "}
            <strong className={styles.medName}>
              {i.medicationPair.drug2.displayName}
            </strong>
          </div>
          {i.drugInteractionDetails.map((details) => (
            <>
              <Accordion.Toggle
                as={Card.Header}
                id={i.interactionId}
                eventKey={details.interactionAssertion}
                className={styles.accordianHeader}
              >
                {" "}
                {details.interactionAssertion}
              </Accordion.Toggle>
              <Accordion.Collapse
                className={styles.accordianCollapse}
                eventKey={details.interactionAssertion}
              >
                <Card.Body className={styles.innerInteractionCard}>
                  {details.description}

                  <div className={styles.interactionRow}>
                    {" References: "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={details.linkList[0].link}
                    >
                      {details.linkList[0].display}
                    </a>
                    {"    ||    "}

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={details.linkList[1].link}
                    >
                      {details.linkList[1].display}
                    </a>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </>
          ))}{" "}
        </Card>
      ))
    ) : isFetching ? (
      <Card>
        <strong>Finding Interactions...</strong>
        {/* <div className="loader"></div> */}
      </Card>
    ) : (
      <Card>
        <strong>No interactions found</strong>
      </Card>
    );
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      //centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Interactions with current medications
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion
          className="accordion accordion-flush"
          id="accordionFlushExample"
        >
          {handleInteractions()}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-info" onClick={() => assignOrderDelegate()}>
          <>
            {isFetching ? (
              <div className="spinner-border"></div>
            ) : (
              <div> Confirm order with interactions</div>
            )}
          </>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InteractionsModal;
