import React, { forwardRef, useRef, useImperativeHandle } from "react";
import styles from "./PatientPage.Module.scss";

function AutoCompleteComponent(
  {
    termList,
    searchInputRef,
    searchInput,
    inputHasFocus,
    setInputFocus,
    handleOptionClick,
  },
  ref
) {
  const datalistRef = useRef();
  useImperativeHandle(ref, () => ({
    focus: () => {
      // datalistRef.current.focus();
      datalistRef.current.firstChild.focus();
    },
  }));
  const inputFocusStyles = {
    display: "block",
    width:
      searchInputRef.current != null
        ? searchInputRef.current.offsetWidth + "px"
        : "0px",
    left:
      searchInputRef.current != null
        ? searchInputRef.current.getBoundingClientRect().left + "px"
        : "0px",
    top:
      searchInputRef.current != null
        ? searchInputRef.current.getBoundingClientRect().top +
        searchInputRef.current.offsetHeight +
        "px"
        : "0px",
    maxHeight: "150px",
  };
  const arrarr = termList.filter(
    (el) => el.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
  );

  const sortedList =
    searchInput.length > 2
      ? arrarr.filter(
        (term) => term[0].toLowerCase() === searchInput[0].toLowerCase()
      )
      : [];
  //using two arrays here let the terms that begin with the same letter as the search term be place first, followed by others that match
  const renderList =
    searchInput.length > 2
      ? sortedList.concat(
        ...arrarr.filter(
          (a) => a[0].toLowerCase() !== searchInput[0].toLowerCase()
        )
      )
      : [];
  function RenderListItems({
    searchInputRef,
    // medRef,
    setInputFocus,
    handleOptionClick,
  }) {
    const handleKeyPressList = (event) => {
      switch (event.key) {
        case "Enter":
          event.preventDefault();
          handleOptionClick(event.target.value);
          setInputFocus(false);
          searchInputRef.current.focus();
          break;
        case "ArrowDown":
          if (event.target.nextSibling != null) {
            event.preventDefault();
            event.target.nextSibling.focus();
          }
          break;
        case "ArrowUp":
          if (event.target.previousSibling != null) {
            event.preventDefault();
            event.target.previousSibling.focus();
          } else {
            setInputFocus(false);
            searchInputRef.current.focus();
          }
          break;
        default: break
      }
    };
    return renderList.map((term) => (
      <option
        tabIndex="1"
        // ref={medRef}
        key={term}
        // onFocus={console.log(term)}
        className={styles.autoCompleteItem}
        onClick={() => {
          handleOptionClick(term);
          setInputFocus(false);
        }}
        onKeyDown={handleKeyPressList}
      >
        {term}
      </option>
    ));
  }
  return (
    <datalist
      ref={datalistRef}
      id="datalist"
      style={inputHasFocus ? inputFocusStyles : {}}
    >
      <RenderListItems
        searchInputRef={searchInputRef}
        // medRef={itemRef}
        setInputFocus={setInputFocus}
        handleOptionClick={handleOptionClick}
      ></RenderListItems>
    </datalist>
  );
}
// AutoCompleteComponent = forwardRef(AutoCompleteComponent);
export default forwardRef(AutoCompleteComponent);
