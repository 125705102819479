import { ServiceBase } from "../../ServiceBase";

// const url = process.env.API_URL + "patients/";

export default class PatientService extends ServiceBase {
  constructor() {
    super();
    this.url = this.baseUrl + "/api/Patient/";
  }
  async search(limitTo, firstName) {
    if (firstName == null) {
      firstName = "";
    }
    // const urlQuery = `${uri}firstname=${firstName}`;

    const response = await this.requestJson({
      method: "GET",
      url: `${this.url}Search`,
      data: { firstName: firstName, limitTo: limitTo }, //Base will stringify
    });
    return response;
  }

  async nextPatients(nextLink, count) {
    const uri = `${this.url}Next?count=${count}`;
    const result = await fetch(uri, {
      method: "get",
      dataType: "json",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Headers": "*",
      },
    }).then((response) => response.json());
    return result;
  }
  async previousPatients(nextPreviousLink, count) {
    const uri = `${this.url}Previous?count=${count}`;
    const result = await fetch(uri, {
      method: "get",
      dataType: "json",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Headers": "*",
      },
    }).then((response) => response.json());
    return result;
  }
  async read(id) {
    const result = await this.requestJson({
      url: `/api/Patient/Read?${id}`,
      method: "GET",
    });
    return result;
  }
  async getInteractions(id) {
    const result = await this.requestJson({
      url: `/api/Medications/?${id}`,
      method: "GET",
    });
    return result;
  }
}
